import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PageComp from '../components/PageComp'
import LinkCloud from '../components/LinkCloud/LinkCloud'

const PageTemplate = ({ data }) => {
  const { wpPage: page } = data
  const { title: siteTitle, siteUrl } = data.site.siteMetadata

  return (
    <Layout>
      <Helmet>
        <title>{`${page.title} | ${siteTitle}`}</title>
        <link rel="canonical" href={`${siteUrl}/${page.slug}/`} />
        <meta name="description" content={page.title} />
        <meta name="og:title" content={`${page.title} | Pretparkvoordeel.be`} />
        <meta name="og:description" content={`${page.title}`} />
      </Helmet>
      <PageComp title={page.title} content={page.content} />
      <LinkCloud />
    </Layout>
  )
}

export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
