import React from 'react'
import PropTypes from 'prop-types'

const PageComp = ({ title, content, contact }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-3">{title}</h1>
              {content ? (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ) : null}
              {contact ? (
                <form
                  name="contact"
                  method="POST"
                  action="/contact-bedankt"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="hidden">
                    <label>
                      Don’t fill this out if you're human:{' '}
                      <input name="bot-field" />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label">Uw naam:</label>
                    <input className="input" type="text" name="name" />
                  </div>
                  <div className="field">
                    <label className="label">Uw e-mail:</label>
                    <input className="input" type="email" name="email" />
                  </div>
                  <div className="field">
                    <label className="label">Boodschap:</label>
                    <textarea className="textarea" name="message" />
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className="button is-danger is-medium"
                    >
                      Verzenden
                    </button>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PageComp

PageComp.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contact: PropTypes.bool,
}
